import React, { useState } from 'react'

import { Progress, useInterval } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => {
  const [progress, setProgress] = useState(0)

  const progressAnimation = (seed: number) => {
    const newValue = seed + Math.random() / 4
    return newValue > 1 ? 0 : newValue
  }

  useInterval(() => setProgress(progressAnimation(progress)), 1000)

  return (
    <Content
      heading="Progress"
      components={[{ component: Progress }]}
    >
      <Section>
        <Playground>
          <Progress
            value={progress}
            max={1}
          />
        </Playground>
        <Playground>
          <Progress
            value={380}
            max={400}
            percentagePlacement="outside"
          />
        </Playground>
        <Playground>
          <Progress
            max={100}
            percentagePlacement="outside"
          />
        </Playground>
      </Section>
    </Content>
  )
}

export default Page
