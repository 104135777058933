import { useRef, useEffect } from 'react';

const useInterval = (callback, delay) => {
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    const handler = function () {
      return savedCallback.current(...arguments);
    };
    const id = setInterval(handler, delay);
    return () => clearInterval(id);
  }, [delay]);
};

export { useInterval };
